import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Participant } from '@wix/ambassador-challenges-v1-participant/types';
import { getUserFromConfig } from './userContextHelpers';
import { isForcedPreviewVisitor } from '../../../selectors/isForcedPreview';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import userTypeHandlers from './userTypeHandlers';
import {
  myProgram,
  listParticipants,
} from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../../services/request';

export async function getParticipant(
  flowAPI: ControllerFlowAPI,
): Promise<Participant> {
  /*
    Reject request for participant if owner wants to preview the Visitor page from BM.
   */
  if (
    isForcedPreviewVisitor(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    )
  ) {
    return null;
  }

  // Inside the editor user can't be an participant.
  if (flowAPI.environment.isEditor) {
    return null;
  }

  const user = await getUserFromConfig(flowAPI.controllerConfig);
  const challengeId = await getChallengeId(flowAPI);

  if (user?.loggedIn && challengeId) {
    try {
      const myProg = (
        await request(
          flowAPI,
          myProgram({
            programId: challengeId,
          }),
        )
      )?.data;

      if (myProg?.participant) {
        return myProg.participant;
      }

      const { participants } = (
        await request(
          flowAPI,
          listParticipants({
            challengeId,
            memberId: user.id,
          }),
        )
      )?.data;

      const activeParticipant = participants.find((participant) => {
        return !userTypeHandlers.isUserLeftOrRejected(
          participant.transitions[0].state,
        );
      });

      return activeParticipant || participants?.[0] || null;
    } catch (err) {
      console.error('[Challenges]: Error at get participant request.');
      console.error(err);
    }
  }

  return null;
}
