import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantStepsContext } from './ParticipantStepsContext';
import { onParticipantLogin } from '../User/userProviderPropsMap';
import {
  getStepNavigationInfo,
  handleUserLogin,
  loadParticipantSteps,
  updateParticipantStepStatus,
} from './helpers';

export const participantStepsDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantStepsContext> {
  onParticipantLogin(handleUserLogin);
  const { selectedStep, selectedDate } = await getStepNavigationInfo(flowAPI);

  const isSidebarLayout = true; // leftover from the old times, leave it here to not forgot about paging in the future

  return {
    selectedStep,
    selectedDate: selectedDate?.valueOf() as any,
    participantSteps: await loadParticipantSteps(
      flowAPI,
      selectedDate,
      isSidebarLayout,
    ),
    updateParticipantSteps: async (
      currentDate: Date = null,
      includeAll: boolean = false,
    ) => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(
          flowAPI,
          currentDate,
          includeAll || isSidebarLayout, // we need always load all steps for sidebar layout until we add paging
        ),
      });
    },
    isParticipantStepsLoading: false,
    updateParticipantStepStatus: async (payload) =>
      updateParticipantStepStatus(flowAPI, payload),
  };
};
