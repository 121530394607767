import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ISettingsEvents, RolesState } from './Widget/settingsEvents';
import { ButtonState } from '../ChallengesList/Widget/settingsEvents';
import { isParticipantPage } from '../../selectors/participants';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { ISidebarMobileScreenFromSettings } from './Settings.mobile/tabs/Design/DesignSettings';
import { LayoutSelectedTab } from './Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';
import { challengeDataProviderPropsMap } from '../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import { locationProviderPropsMap } from '../../contexts/Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';

export const initChallengePage = async (flowAPI: ControllerFlowAPI) => {
  const { setProps } = flowAPI.controllerConfig;
  const { isEditor } = flowAPI.environment;

  try {
    const initialProps = {
      ...(await providersPropsMap({
        flowAPI,
        biSettings: {
          preventAutomaticallySendChallengeId: isEditor,
          screenName: ScreenNames.ChallengePage,
        },
        enabledProviders: [
          'challengeDataProvider',
          'inviteLinkProvider',
          'paidPlansProvider',
          'participantSectionsDataProvider',
          'participantStepsDataProvider',
          'resolveStepDataProvider',
          'badgesProvider',
          'socialGroupsProvider',
          'participantProvider',
          'fileUploadProvider',
        ],
      })),
    };

    renderSeoMetatagsPage(flowAPI, initialProps?.challengeData?.challenge);

    flowAPI.bi.updateDefaults({
      origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
      screenName: isParticipantPage(initialProps as any)
        ? ScreenNames.ChallengePageForParticipant
        : ScreenNames.ChallengePage,
      challengeId: initialProps.challengeData?.challenge?.id,
    });

    setProps({ ...initialProps });
  } catch (err) {
    console.error(err);
  }
};

export const clearProvidersCache = async () => {
  challengeDataProviderPropsMap?.cache?.clear();
  locationProviderPropsMap?.cache?.clear();
  userProviderPropsMap?.cache?.clear();
};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);

  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on('LayoutSelectedTab', (value) => {
    setProps({
      shownTab: value,
    });
  });

  settingsEventsHandler.on('sidebarLayoutMobileScreen', (value) => {
    const newProps: any = {};

    newProps.sidebarLayoutMobileScreen = value;

    if (value === ISidebarMobileScreenFromSettings.Overview) {
      newProps.shownTab = LayoutSelectedTab.Overview;
    } else if (value === ISidebarMobileScreenFromSettings.Schedule) {
      newProps.shownTab = LayoutSelectedTab.Schedule;
    }

    setProps(newProps);
  });

  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      pageRole: RolesState.Visitor,
      sidebarLayoutMobileScreen: null,
    });
  });

  return {
    async pageReady() {
      await initChallengePage(flowAPI);

      flowAPI.controllerConfig.wixCodeApi?.location?.onChange(async () => {
        await clearProvidersCache();
        await initChallengePage(flowAPI);
      });
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
